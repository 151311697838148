export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";
export const LOGOUT_PROFILE = "LOGOUT_PROFILE ";

// export const fetchProfile = (products) => {
//   return (dispatch) => {
//     axios
//       .get(`${process.env.REACT_APP_BACKEND}/api/product`)
//       .then((result) => {
//         dispatch(fetchProductsSuccess(result.data));
//       })
//       .catch((error) => {});
//   };
// };

export const changeProfile = (info) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: info,
  };
};

export const removeprofile = () => {
  return {
    type: LOGOUT_PROFILE,
  };
};
