import React, { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";

const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const MyAccount = lazy(() => import("./pages/account/MyAccount"));
const LoginRegister = lazy(() => import("./pages/login/LoginRegister"));
const Login = lazy(() => import("./pages/login/Login"));
const ForgetPassword = lazy(() => import("./pages/reset/Forgetpassword"));
const ResetPassword = lazy(() => import("./pages/reset/ResetPassword"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Upload = lazy(() => import("./pages/Upload/Upload"));

const Checkout = lazy(() => import("./pages/other/Checkout"));
const Order = lazy(() => import("./pages/other/Order"));

const NotFound = lazy(() => import("./pages/account/NotFound"));

const App = ({}) => {
  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="sdet-u-preloader-wrapper">
                  <div className="sdet-u-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                  component={ShopGridStandard}
                />

                <GuestRoute
                  path={process.env.PUBLIC_URL + "/forget-password"}
                  component={ForgetPassword}
                />
                <GuestRoute
                  path={process.env.PUBLIC_URL + "/reset-password/:token"}
                  component={ResetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/upload"}
                  component={Upload}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <PrivateRoute
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/register"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={Login}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <PrivateRoute
                  component={Checkout}
                  path={process.env.PUBLIC_URL + "/checkout"}
                />

                <PrivateRoute
                  path={process.env.PUBLIC_URL + "/order"}
                  component={Order}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};
export default connect(mapStateToProps, null)(App);
