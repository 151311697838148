import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { composeWithDevTools } from "redux-devtools-extension";
import { fetchProducts } from "./redux/actions/productActions";
import {
  ApolloProvider as RouteProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND}/test/api/graphql`,
  cache: new InMemoryCache(),
});

export const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);
store.dispatch(fetchProducts());

ReactDOM.render(
  <Provider store={store}>
    <RouteProvider client={client}>
      <App />
    </RouteProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
