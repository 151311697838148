import { FETCH_PRODUCTS_ERROR, FETCH_PRODUCTS_START, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCT_BY_ID_SUCCESS } from "../actions/productActions";

const initialState = {
  products: [],
  singleProduct: null,
  isLoading: false,
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START:
      return { ...state, isLoading: true, error: null };
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, isLoading: false, products: action.payload };
    case FETCH_PRODUCT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, singleProduct: [action.payload] };
    case FETCH_PRODUCTS_ERROR:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default productReducer;
