import axios from "axios";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';


const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

export const fetchProducts = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/test/products`)
      .then((result) => {
        dispatch(fetchProductsSuccess(result.data));
      })
      .catch((error) => {});
  };
};

export const fetchProductById = (productId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_START });

    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/test/products/${productId}`)
      .then((result) => {
        dispatch({ type: FETCH_PRODUCT_BY_ID_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PRODUCTS_ERROR, error: error });
      });
  };
};
