import {
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  LOGOUT_PROFILE,
} from "../actions/profileAction";

const initState = {
  user: null,
};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      console.log(action);
      return {
        user: action.payload,
      };
    case FETCH_PROFILE_ERROR:
      return initState;
    case LOGOUT_PROFILE:
      localStorage.removeItem("token");
      return initState;
    default:
      return state;
  }
};

export default profileReducer;
